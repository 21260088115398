.navbar__container {
    width: 100%;
    background: rgb(255, 214, 63);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.navbar__logo--subcontainer {

}

.navbar__header {
    font-size: 4rem;
    color: black;
    font-weight: 200;
}

.navbar__ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    text-align: center;
    padding: 0 0 0 0;
}

.navbar__link {
    font-size: 2rem;
    text-decoration: none;
    color: rgb(103, 103, 103);
    transition: .5s;
    font-weight: 800;
}

.navbar__link:hover {
    color: rgb(0, 0, 0);
}